import React, { useState, useEffect } from "react";
import Card from "../Card/Card";
const Guide1 = () => {
  const [topSection, setTopSection] = useState(false);

  const handleTopSection = () => {
    setTopSection(true);
  };

  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

  useEffect(() => {
    // Listener for messages from the extension
    const messageListener = (event) => {
      if (event.source !== window) return;
      if (event.data.type === "EXTENSION_INSTALLED") {
        console.log("Extension is installed:", event.data.data);
        setIsExtensionInstalled(true);
      }
    };

    // Add event listener for messages
    window.addEventListener("message", messageListener);

    // Send a message to check if the extension is installed
    window.postMessage({ type: "CHECK_EXTENSION" }, "*");

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);
  return (
    <>
      <div className="header-section">
        <div className="info ">
          {/* {
                        !topSection &&
                        <div className="guide-info text-color-gray-light">
                            <h2 className=' font-bold text-[24px]'>Get ready to setup your website and add your footprints to it</h2>
                            <p className='text-xs'>We’ll hand hold you through the process of adding your website and then setting up your footprints.</p>
                        </div>
                    } */}

          <div className="setup-guide mt-5">
            <div className="inner-setup-guide text-color-gray-light">
              <Card showSection={handleTopSection} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Guide1;




























//------------------------------------------------------>
// const injectCardContent = () => {
//   // Create a shadow host container
//   const shadowHost = document.createElement("div");
//   shadowHost.style.position = "fixed";
//   shadowHost.style.top = "20px";
//   shadowHost.style.left = "20px";
//   shadowHost.style.zIndex = "9999999";
//   shadowHost.style.background="red";

//   // Attach Shadow DOM
//   shadowRoot = shadowHost.attachShadow({ mode: "open" });

//   // Create a style element and append the styles to the Shadow DOM
//   const style = document.createElement("style");
//   style.textContent = `
//   * {
//   all: initial;
// }

//     .container-2 {
//       width: 363px;
//       cursor: move;
//       border-radius: 10px;
//     }
 
//     .top-section {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//     }
//     .tagline {
//       color: #fff;
//       font-size: 20px;
//       font-weight: bold;
//       margin: 0;
//     }
//     .cross-icon svg {
//       cursor: pointer;
//     }
//     .step-button {
//       background-color: #fff;
//       border: 1px solid #616161;
//       border-radius: 5px;
//       color: #000;
//       padding: 5px 10px;
//       font-size: 14px;
//       margin-top:10px;
//     }
//     .click-count {
//       margin-top: 5px;
//     }
//     .drag-button {
//           border: 1px solid #282828;
//             padding: 10px;
//             text-align: center;
//             border-radius: 0.75rem;
//             background:white;
//             font-weight:400;
//             font-size:14px;
//             width:100%;
//             text-color:white;
//       cursor: move;
//       display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 10px;
//     }
   
//     .highlight {
//       background-color: yellow !important;
//     }
//     .step-container {
//       display: inline-block;
//     }

//     .button-container {
//   width: 100%; 
// }

// .step-container {
//   display: flex; 
//   gap: 10px; 
// }

// .step-button {
//   flex: 1; 
//   // max-width: 20%; 

//   max-width: 30%; 
//   white-space: nowrap; 
// }



//     .inner-wrapper {
//             background-color: white;
//             width: 300px;
//             padding: 1.5rem 1.5rem;
//             border-radius: 1.5rem;
//         }
//         .outer-section {
//             display: flex;
//             flex-direction: column;
//             gap: 10px;
//             margin-bottom:10px;   // 26 added
//         }
//         .inner-top {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//         }
//         .cross-icon {
//             padding: 0.5rem;
//         }
        
//         .bottom-section {
//          border-radius:12px;
//         background:#F9F9F9;
//         padding-top:10px;
//         padding-bottom:10px;
//         } 

//         .drop-down-container{
//            height: 200px; 
//     overflow: auto; 
//     display: flex;
//     flex-direction: column;
//     gap: 10px;
//         }
//         .info {

        
//             display: flex;
//             flex-direction: column;
//             gap: 10px;
//             padding-left: 10px;
//             padding-right:10px;
//         }
//         .title h2 {
//             text-align: center;
//             font-weight: bold;
//             font-size: 16px;
//             margin:0;
//         }
//         .drop-down {
//             background-color: white;
//             border-radius: 8px;
//             margin-bottom:10px;  // new added
//         }
//         .expandable-container {
//             background-color: #E0E0E0;
//             border-radius: 8px;
//         }
//         .with-random {
//             display: flex;
//             flex-direction: column;
            
//         }
//         .both-wrapper {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             font-size: 16px;
//             padding: 8px 0px 0px 10px;
//         }
//         .left-icon {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             gap: 8px;
//         }
//         .steps {
//             color: #818181;
//             font-weight:400;
//         }
//         .selector-text {
//             color: #616161;
//         }
//         .right-icon {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             gap: 16px;
//             padding-right: 10px;
//             padding-left: 10px;
//         }
//             .drop-icon{
//             margin-top:-3px;
//             cursor:pointer;
//             }
//         .choice {
//             display: flex;
//             justify-content: flex-start;
//             gap: 8px;
//             align-items: center;
//             padding-top: 4px;
//             padding-bottom:4px;
//             padding-left:8px;
//             padding-right:8px;
//              color: #616161;
//             padding-bottom:8px;

//         }
      
      
//         .counter {
//             padding-left: 10px;
//             padding-right:10px;
           
//         }
//         .extra-content p, .button-container p {
//             font-size: 16px;
//             font-weight: normal;
//         }

//         .button-container{
//         display:flex;
//         flex-direction:column;
//         gap:8px;
//         }
    
   

//          .finish-button {
//             background-color: #414141;
//             color: white;
//             padding: 10px;
//             border-radius: 0.75rem;
//             border: 1px solid #282828;
//             padding: 10px;
//             text-align: center;
//             border-radius: 0.75rem;
//             font-weight:400;
//             font-size:14px;
//             width:100%;
//             text-color:white;
//             cursor:pointer;
//         }

//           .tagline-text  {
//       font-size: 14px;
//       font-weight: 400;
//       margin:0;
//       font-family: "Manrope", sans-serif;

//     }

//     .expanded-section{
//     padding-top:10px;
//     padding-bottom:10px;
//     color:#818181;
//     padding-left:8px;
//     padding-right:8px;
//     font-size:16px;
//     display: none;
//     }
//     .content{
//           font-family: "Manrope", sans-serif;
//           margin:0;
//           font-size:12px;
//     word-wrap: break-word; 
//     overflow-wrap: break-word; 
//     white-space: normal; 
//     }

//     .icon-small{
//     display:flex;
//     justify-content:center;
//     align-items:center;
//     gap:10px;
//     font-size:12px;
//     color:#616161;
//     }
    
//     .random{
//     font-size:12px;
//     }

//     .action-container{
//     display:none;
//     background:white;
//     padding:10px;
//     border-radius:8px;
//     }

//     .action-button-container{
//     display:flex;
//     border-radius:8px;
//     display:flex ;
//     justify-content:space-between;
//     align-itmes:center;
//     gap:4px;
//     }

//     .left-button{
//      display:flex ;
//     justify-content:space-between;
//     align-itmes:center;
//     gap:8px;
    
//     }

//     .delete-step{
//         border-radius:8px;
//         border:1px;
//         background:#414141;
//         font:normal;
//         font-size:12px;
//         color:white;
//         padding:8px;
//         text-align:center;    
//         cursor:pointer;    

//     }

//   .delete-action{
//         border-radius:8px;
//         border:1px solid #616161;
//         font:normal;
//         background:white;
//         font-size:12px;
//         padding:8px;
//         text-align:center;   
//         cursor:pointer;    
//         display:none; 

//     }

//     .right-button{
//      display:flex ;
//     justify-content:space-between;
//     align-items:center;
//     gap:24px;
//     }

//     .cross-icon-step{
//     cursor:pointer;
//     }

//     .image-container{
//     margin:auto;
//     }

//     ::-webkit-scrollbar {
//   width: 6px;
// }

// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// ::-webkit-scrollbar-thumb {
//   background: #A1A1A1;
//   border-radius: 5px;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }
//   `;

//   // Append the style to the shadow root
//   shadowRoot.appendChild(style);

//   // Create the container and card content
//   const container = document.createElement("div");
//   container.className = "container-2";
//   container.innerHTML = `


//       <div class="wrapper-2">
   
   
//         <div class="inner-wrapper">
//         <div class="outer-section">
//             <div class="top-section">
//                 <div class="inner-top-section">
//                     <div class="inner-top">
//                         <div class="empty"></div>
//                            <div class="logo">
//                                         <svg width="142" height="20" viewBox="0 0 142 27" fill="none" xmlns="http://www.w3.org/2000/svg">

//                                         </svg>
//                                     </div>
//                         <div class="cross-icon">
//                             <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                         </svg>
//                         </div>
//                     </div>
//                     <p class="tagline-text">Create a footprint by performing the steps that you want the system to replicate.</p>
//                 </div>
//             </div>
//             <div class="bottom-section">
//                 <div class="info">
//                     <div class="title">
//                         <h2>Recorded steps</h2>
//                     </div>

                   
//                      <div class="image-container">
//     <svg width="160" height="101" viewBox="0 0 160 101" fill="none" xmlns="http://www.w3.org/2000/svg">
// </svg>


//                     </div>
//                     <div class="drop-down-container" >

//                     </div>

                          

//                     </div>
                  
//                     <div class="counter">
//                         <button class="step-button">${capturedSelectors.length} steps</button>
//                     </div>
//                 </div>
//             </div>


        

//             <div class="button-container">
//                 <div class="drag-container">
//                     <button  id="botton_drag_finish" class="drag-button" draggable="true">
//                       <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                           
//                                     </svg>
//                         <span>Drag me to finish</span>
//                     </button>
//                 </div>
//                 <div class="drag-container"  >
//                     <button  id="button_finish" class="finish-button">Finish</button>
//                 </div>
//             </div>
//         </div>
//     </div>

   
//     `;

//   shadowRoot.appendChild(container);
//   document.body.appendChild(shadowHost);

//   const crossIcon = shadowRoot.querySelector(".cross-icon");
//   crossIcon.addEventListener("click", () => {
//     shadowHost.remove();
//     localStorage.clear();
//     chrome.storage.local.remove("footprintData", function () {
//       console.log("Footprint data cleared");
//     });
//     chrome.storage.local.remove("clientUrl", function () {
//       console.log("Footprint data cleared");
//     });
//   });

//   const dragButton = shadowRoot.querySelector(".drag-button");
//   const finishButton = shadowRoot.querySelector(".finish-button");

//   let previousDropElement = null;
//   let dropIndex = 0;

//   dragButton.addEventListener("dragstart", (e) => {
//     e.dataTransfer.setData("text/plain", "drag-button");
//     isDraggingContainer = false;
//   });

//   document.addEventListener("dragover", (e) => {
//     e.preventDefault();
//   });

//   document.addEventListener("drop", (e) => {
//     e.preventDefault();

//     if (e.dataTransfer.getData("text") === "drag-button") {
//       if (previousDropElement) {
//         previousDropElement.classList.remove("highlight");
//         previousDropElement.style.backgroundColor = ""; // Reset inline background color
//       }

//       const dropElement = e.target;
//       dropElement.style.backgroundColor = "yellow"; // Apply highlight
//       const selector = getSelector(dropElement);

//       if (dropElement) {
//         dropElement.classList.add("highlight");
//       }
//       dropIndex += 1;

//       let value = dropElement.value || dropElement.innerText;
//       const tag = dropElement.tagName.toLowerCase();
//       const type = dropElement.type || "";
//       const innerText = dropElement.innerText || "";
//       const xpath = getXPath(dropElement);

//       capturedSelectors.push({
//         selector,
//         tag,
//         type,
//         innerText,
//         value,
//         xpath,
//         dropIndex,
//       });

//       saveToLocalStorage();
//       updateClickCount();

//       previousDropElement = dropElement;
//       finishButton.style.display = "block";
//     }
//   });

//   finishButton.addEventListener("click", () => {
//     sendDataToServer();
//   });

//   let isDraggingContainer = false;
//   let currentX;
//   let currentY;
//   let initialX;
//   let initialY;
//   let xOffset = 0;
//   let yOffset = 0;

//   container.addEventListener("mousedown", (e) => {
//     initialX = e.clientX - xOffset;
//     initialY = e.clientY - yOffset;

//     if (e.target === container || e.target.closest(".wrapper-2")) {
//       isDraggingContainer = true;
//     }
//   });

//   document.addEventListener("mousemove", (e) => {
//     if (isDraggingContainer) {
//       e.preventDefault();
//       currentX = e.clientX - initialX;
//       currentY = e.clientY - initialY;

//       xOffset = currentX;
//       yOffset = currentY;

//       setTranslate(currentX, currentY, shadowHost);
//     }
//   });

//   document.addEventListener("mouseup", (e) => {
//     initialX = currentX;
//     initialY = currentY;
//     isDraggingContainer = false;
//   });

//   function setTranslate(xPos, yPos, el) {
//     el.style.transform = `translate3d(${xPos}px, ${yPos}px, 0)`;
//   }
// };





