import { useState } from 'react';
import { baseUrl } from '../config';


const publicVapidKey="BAofMcLbB6LjdSNqNVQZYeYVzWvlIi0CNIdIpJS0v-BO8xIxraK935pyGwLqMf924QyVAoR-R6XO9YVSy01kTOM";
const privateVapidKey="BMv7sbvUhOV5n1x8aD5qY1DDhNRGFHu4o4-9_D3u10A"


// Helper function to convert VAPID public key to Uint8Array
function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

export default function usePushNotifications() {
  const [isSubscribed, setIsSubscribed] = useState(false);

  const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
      try {
        console.log('Registering Service Worker...');
        const registration = await navigator.serviceWorker.register('/worker.js');
        console.log('Service Worker Registered:', registration);
        return registration;
      } catch (error) {
        console.error('Service Worker registration failed:', error);
      }
    } else {
      console.error('Service workers are not supported in this browser.');
    }
  };

  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      
      


    } else {
      console.error('Notification permission denied.');
    }
    return permission;
  };

//   const subscribeUserToPush = async (registration) => {
//     try {
//       console.log('Registering for Push Notifications...');
//       const subscription = await registration.pushManager.subscribe({
//         userVisibleOnly: true,
//         applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
//       });
//       console.log('Push Registration Successful:', subscription);

//       // Send subscription to the server
//       await sendSubscriptionToServer(subscription);
//       setIsSubscribed(true);
//     } catch (error) {
//       console.error('Push subscription failed:', error);
//     }
//   };



  async function registerPush(registration) {
    try {
      console.log('Registering for Push Notifications...');
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
      });
      console.log('Push Registration Successful:', subscription);
  
      // Send subscription details to server
      await sendSubscriptionToServer(subscription);
     

      // Simulate a push event to test notification
    //   registration.active.postMessage({
    //     title: 'Test Notification',
    //     body: 'This is a simulated push notification!',
    //     icon: 'https://moretasks.com/images/morelogo.png',
    //     url: 'https://moretasks.com/'
    //   });
    } catch (error) {
      console.error('Push registration failed:', error);
    }
  }

  const sendSubscriptionToServer = async (subscription) => {
    try {
      console.log('Sending Push Subscription to Server...');

      const userId = localStorage.getItem("userId"); // Fetch userId dynamically
      if (!userId) {
        console.error('User ID is null or undefined.');
        return;
      }

      await fetch(`${baseUrl}/pushNotification/subscribe`, {
        method: 'POST',
        body: JSON.stringify({ subscription, user:userId }),
        headers: { 'Content-Type': 'application/json' },
      });
      console.log('Push Subscription sent successfully.');
      
    } catch (error) {
      console.error('Error sending push subscription to server:', error);
    }
  };

  const initializePushNotifications = async () => {
    const registration = await registerServiceWorker();
    const permission = await requestNotificationPermission();
    if (permission === 'granted') {
    //   await subscribeUserToPush(registration);
      await registerPush(registration);
      setIsSubscribed(true);

    }
  };

  return { initializePushNotifications };
}


