import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../config";
const Metrics = ({ videoId }) => {
    console.log("ye tha na , ", videoId)
    const [data, setData] = useState();


    useEffect(() => {
        // Fetch data from the API and set the input field values
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${baseUrl}/videos/${videoId}/metrics`
                );
                const metrics = response.data;
                console.log(metrics, "kya hai ye")
                setData(metrics);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();

    }, []);


    useEffect(() => {


        console.log(data, "huu")

    }, [data])
    return (
        <div className="container ">
            {/** h-[45vh removed] */}
            <div className="wrapper   pb-4 border rounded-xl bg-white">
                <div className="top-section flex justify-between items-center mx-4 my-2">
                    <div className="content  font-semibold text-xl ">
                        <h3> Performance metrics </h3>
                    </div>
                </div>
                <div className="mid-section rounded-xl  mx-2">
                    <div className="count-info ">
                        <div className="inner-count-info flex flex-col gap-2">


                            <div className="bottom-info flex">
                                <div className="content text-sm mx-1 bg-light-gray   rounded-lg mt-1 p-2 w-1/2" 

                                >
                                    <div className="icon-section flex text-color-gray-light items-center justify-center gap-2">


                                        <p className="text-xl mt-1 font-bold ">  {(data?.performanceScore ?? 0).toFixed(2)} ms
                                        </p>
                                    </div>
                                    <h3 className="text-color-gray-light font-bold text-center">
                                        Performance Score
                                    </h3>
                                </div>
                                <div className="content text-sm mx-1    bg-light-gray rounded-lg mt-1 p-2 w-1/2">
                                    <div className="icon-section flex text-color-gray-light items-center justify-center gap-2">



                                        <p className="text-xl mt-1 font-bold ">{(data?.loadTime ?? 0).toFixed(2)} ms</p>
                                    </div>
                                    <h3 className="text-color-gray-light font-bold text-center">
                                        Page Speed                                    </h3>
                                </div>
                            </div>
                            <div className="bottom-info flex">
                                <div className="content text-sm mx-1   bg-light-gray rounded-lg mt-1 p-2 w-1/2">
                                    <div className="icon-section flex text-color-gray-light items-center justify-center gap-2">


                                        <p className="text-xl mt-1 font-bold">{data?.seoScore}</p>
                                    </div>
                                    <h3 className="text-color-gray-light font-bold text-center">
                                        SEO                                    </h3>
                                </div>
                                <div className="content text-sm mx-1  bg-light-gray rounded-lg mt-1 p-2 w-1/2"
                           

                            >
                                    <div className="icon-section flex text-color-gray-light items-center justify-center gap-2">



                                        {/* <p className="text-xl mt-1 font-bold">
                                            {data?.totalPageSize?.replace("Total size was ", "")}
                                        </p> */}
                                        <p className="text-xl mt-1 font-bold">
                                            {data?.totalPageSize
                                                ? parseInt(data.totalPageSize.replace("Total size was ", ""), 10) > 9999
                                                    ? (parseInt(data.totalPageSize.replace("Total size was ", ""), 10) / 1024 / 1024).toFixed(2) + " MB"
                                                    : data.totalPageSize.replace("Total size was ", "") +"Bytes"
                                                : "No data available"}
                                        </p>


                                    </div>
                                    <h3 className="text-color-gray-light font-bold text-center">
                                        Total Page Size
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Metrics;
