
import React from "react";
import Lottie from "react-lottie";
import success from "../Images/Completed.json";

const ExtensionConfirm = ({ show, onClose }) => {
  if (!show) return null;

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: success, // Lottie animation JSON
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center backdrop-blur">
      <div className="w-64 max-w-md px-8 pt-5 bg-white rounded-lg shadow-md flex flex-col justify-between">
        <div className="container flex flex-col items-center space-y-0">
          <Lottie options={defaultOptions} height={150} width={200} />
          <div className="content text-sm font-semibold text-center pt-4">
            TestTube for Chrome Installed
          </div>
          <div className="text-xs pt-4 text-center">
            Now you can start creating test cases successfully
          </div>
          <div className="bottom-section pt-4 pb-5 w-full">
            <button
              onClick={onClose}
              className="button w-full rounded-lg p-2 bg-button-bg text-white text-xs"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtensionConfirm;
