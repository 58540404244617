// import React, { useEffect, useState } from "react";
// import FailToast from "../Toast/FailToast";
// import SuccessToast from "../Toast/SuccessToast";

// const Notification = () => {
//   const [fail, setFail] = useState([]);
//   const [billing, setBilling] = useState([]);
//   const [activeFail, setActiveFail] = useState(false);
//   const [activeSuccess, setActiveSuccess] = useState(false);
//   const [msg, setMsg] = useState("");
//   const [billingToggleStates, setBillingToggleStates] = useState({
//     billing_email: true,
//     billing_sms: false,
//   });
//   const [failToggleStates, setFailToggleStates] = useState({
//     fail_notification_email: true,
//     fail_notification_sms: false,
//   });
//   const handleChangeNotification = (event, id) => {
//     setFailToggleStates((prevState) => ({
//       ...prevState,
//       [id]: event.target.checked,
//     }));
//   };

//   const handleChangeBilling = (event, id) => {
//     setBillingToggleStates((prevState) => ({
//       ...prevState,
//       [id]: event.target.checked,
//     }));
//   };

//   const handleFilterTrueValues = () => {
//     // const filteredFail = Object.values(failToggleStates).filter(
//     //   (value) => value
//     // ).length;
//     // const filteredFail = Object.entries(failToggleStates)
//     //   .filter(([key, value]) => value === true)
//     //   .map(([key]) => key);

//     if (
//       Array.isArray(billingToggleStates) ||
//       typeof billingToggleStates === "object"
//     ) {
//       const trueBillingToggles = Object.entries(billingToggleStates)
//         .filter(([key, value]) => value === true)
//         .map(([key]) => key);
//       setBilling(trueBillingToggles);
//     }

//     if (
//       Array.isArray(failToggleStates) ||
//       typeof failToggleStates === "object"
//     ) {
//       const trueFailToggles = Object.entries(failToggleStates)
//         .filter(([key, value]) => value === true)
//         .map(([key]) => key);
//       setFail(trueFailToggles);
//     }
//   };

//   const handleNotification = () => {
//     console.log("fail from handleNotification", fail);
//     if (fail.length == 0) {
//       setActiveFail(true);
//       setMsg(
//         "Oops! You need to have at least 1 notification enabled for Foot Print failure notifications."
//       );
//     } else {
//       setActiveSuccess(true);
//       setMsg("Awesome! Notifications settings updated successfully");
//     }
//   };
//   const handleBilling = () => {
//     console.log("fail from handleBilling", billing);

//     if (billing.length == 0) {
//       setActiveFail(true);
//       setMsg(
//         " Oops!You need to have at least 1 notification enabled for billing reminders."
//       );
//     } else {
//       setActiveSuccess(true);
//       setMsg("Awesome! Notifications settings updated successfully");
//     }
//   };
//   useEffect(() => {
//     handleFilterTrueValues();
//   }, [failToggleStates, billingToggleStates]);

//   useEffect(() => {
//     if (activeFail) {
//       setTimeout(() => {
//         setActiveFail(false);
//       }, 3000);
//     }
//     if (activeSuccess) {
//       setTimeout(() => {
//         setActiveSuccess(false);
//       }, 2000);
//     }
//   }, [activeFail, activeSuccess]);

//   return (
//     <div class="bg-[#ffffff]">
//       {activeFail && (
//         <FailToast
//           errorValue={msg}
//           activeFail={activeFail}
//           setActiveFail={setActiveFail}
//         />
//       )}
//       {activeSuccess && (
//         <SuccessToast
//           successValue={msg}
//           activeSuccess={activeSuccess}
//           setActiveSuccess={setActiveSuccess}
//         />
//       )}
//       <div
//         class="flex  flex-col space-y-2  py-3 px-5  mx-auto"
//         style={{ width: "450px", height: "412px" }}
//       >
//         <div class=" mb-1 space-y-1">
//           <h1 class="text-gray-500 font-extrabold text-lg">
//             Notification Manangement
//           </h1>
//           <p class="text-gray-500 font-normal	text-sm	">
//             Manage your notification detail from this section{" "}
//           </p>
//         </div>

//         {/* notification fail */}

//         <div className="bg-[#F1F1F1] p-3  rounded-lg space-y-3">
//           <h1 class="text-gray-600 font-extrabold text-lg">
//             Foot Print Fail Notification
//           </h1>
//           <ul className="mt-8 space-y-2 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
//             {[
//               { label: "Email", id: "fail_notification_email" },
//               // { label: "SMS", id: "fail_notification_sms" },
//             ].map((item) => (
//               <li
//                 key={item.id}
//                 className="flex items-center justify-between w-full space-x-32 bg-[#E5E5E5] p-2 rounded-lg"
//               >
//                 <span className="text-lg font-semibold text-black">
//                   {item.label}
//                 </span>
//                 <input
//                   type="checkbox"
//                   className="peer sr-only opacity-0"
//                   id={item.id}
//                   value={item.label}
//                   checked={failToggleStates[item.id]}
//                   onChange={(e) => handleChangeNotification(e, item.id)}
//                 />
//                 <label
//                   htmlFor={item.id}
//                   className="relative flex h-6 w-11 cursor-pointer items-center rounded-full bg-gray-400 px-0.5 outline-gray-400 transition-colors before:h-5 before:w-5 before:rounded-full before:bg-white before:shadow before:transition-transform before:duration-300 peer-checked:bg-[#1F8B24] peer-checked:before:translate-x-full peer-focus-visible:outline peer-focus-visible:outline-offset-2 peer-focus-visible:outline-gray-400 peer-checked:peer-focus-visible:outline-green-500"
//                 >
//                   <span className="sr-only">Enable</span>
//                 </label>
//               </li>
//             ))}
//           </ul>
//           <div class="flex gap-3">
//             <button
//               class="py-2 px-2 rounded-lg text-xs font-medium bg-btn-gradient-auth text-white"
//               onClick={handleNotification}
//             >
//               Save
//             </button>
//           </div>
//         </div>

//         <div className="bg-[#F1F1F1] p-3 rounded-md space-y-3">
//           <h1 class="text-gray-600 font-extrabold text-lg">
//             Billing Notification
//           </h1>
//           <ul className="mt-8 space-y-2 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
//             {[
//               { label: "Email", id: "billing_email" },
//               // { label: "SMS", id: "billing_sms" },
//             ].map((item) => (
//               <li
//                 key={item.id}
//                 className="flex items-center justify-between w-full space-x-32 bg-[#E5E5E5] p-2 rounded-lg"
//               >
//                 <span className="text-lg font-semibold text-black">
//                   {item.label}
//                 </span>
//                 <input
//                   type="checkbox"
//                   className="peer sr-only opacity-0"
//                   id={item.id}
//                   value={item.label}
//                   checked={billingToggleStates[item.id]}
//                   onChange={(e) => handleChangeBilling(e, item.id)}
//                 />
//                 <label
//                   htmlFor={item.id}
//                   className="relative flex h-6 w-11 cursor-pointer items-center rounded-full bg-gray-400 px-0.5 outline-gray-400 transition-colors before:h-5 before:w-5 before:rounded-full before:bg-white before:shadow before:transition-transform before:duration-300 peer-checked:bg-[#1F8B24] peer-checked:before:translate-x-full peer-focus-visible:outline peer-focus-visible:outline-offset-2 peer-focus-visible:outline-gray-400 peer-checked:peer-focus-visible:outline-green-500"
//                 >
//                   <span className="sr-only">Enable</span>
//                 </label>
//               </li>
//             ))}
//           </ul>
//           <div class="flex ">
//             <button
//               class="py-2 px-2 rounded-lg text-xs font-medium bg-btn-gradient-auth text-white"
//               onClick={handleBilling}
//             >
//               Save
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Notification;

import React, { useEffect, useState } from "react";
import FailToast from "../Toast/FailToast";
import { baseUrl } from "../../config";
import axios from "axios"
import SuccessToast from "../Toast/SuccessToast";
import usePushNotifications from "../../hooks/usePushNotifications";


const Notification = () => {
  const [fail, setFail] = useState([]);
  const [billing, setBilling] = useState([]);
  const [activeFail, setActiveFail] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSubscribe , setIsSubscribe]= useState()
  const { initializePushNotifications } = usePushNotifications();
  const [filteredOptions, setFilteredOptions] = useState([]);
 
  const [billingToggleStates, setBillingToggleStates] = useState({
    billing_email: true,
    billing_sms: false,
  });
  const [failToggleStates, setFailToggleStates] = useState({
    fail_notification_email: true,
    fail_notification_push:true
  });
  const notificationOptions = [
    { label: "Email", id: "fail_notification_email" },
    { label: "Push Notification", id: "fail_notification_push" },
  ];


  const fetchUserSubscription = async () => {
    try {
     const userId = localStorage.getItem("userId")
      const response = await axios.get(`${baseUrl}/pushNotification/subscription-status/${userId}`);
      console.log("response from pushNotification", response.data.isSubscribed)
      setIsSubscribe(response?.data?.isSubscribed)

     
    
    } catch (error) {
      console.log('Error fetching PushNotification:', error.response);
     
    }
    return;
  };


  const deleteUserSubscription = async ()=>{
    const userId = localStorage.getItem("userId")
        try {
          const response = await axios.delete(
            `${baseUrl}/pushNotification/delete-subscription/${userId}`
          );
          setIsSubscribe(false)
          console.log("response from deleteSubscription", response.data);
        } catch (error) {
          console.log("Error in fetching data", error.response);
        }
        return;
     
  }


 
  const handleChangeNotification = async(event, id) => {
 
    if(!isSubscribe && id === "fail_notification_push"){
      await initializePushNotifications()
      await fetchUserSubscription()
      
    
    }

    if( isSubscribe && id === "fail_notification_push"){
        await deleteUserSubscription()
        // console.log("isSubScribed",isSubscribed);
    }

    setFailToggleStates((prevState) => ({
      ...prevState,
      [id]: event.target.checked,
    }));

   
  };

  const handleChangeBilling = (event, id) => {
    setBillingToggleStates((prevState) => ({
      ...prevState,
      [id]: event.target.checked,
    }));
  };

 

  const handleFilterTrueValues = () => {
   
    if (
      Array.isArray(billingToggleStates) ||
      typeof billingToggleStates === "object"
    ) {
      const trueBillingToggles = Object.entries(billingToggleStates)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);
      setBilling(trueBillingToggles);
    }

    if (
      Array.isArray(failToggleStates) ||
      typeof failToggleStates === "object"
    ) {
      const trueFailToggles = Object.entries(failToggleStates)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);
      setFail(trueFailToggles);
     
    }
  };

  const handleNotification = () => {
    console.log("fail from handleNotification", fail);
    if (fail.length == 0) {
      setActiveFail(true);
      setMsg(
        "Oops! You need to have at least 1 notification enabled for Foot Print failure notifications."
      );
    } else {
      setActiveSuccess(true);
      setMsg("Awesome! Notifications settings updated successfully");
    }
  };
  const handleBilling = () => {
    console.log("fail from handleBilling", billing);

    if (billing.length == 0) {
      setActiveFail(true);
      setMsg(
        " Oops!You need to have at least 1 notification enabled for billing reminders."
      );
    } else {
      setActiveSuccess(true);
      setMsg("Awesome! Notifications settings updated successfully");
    }
  };
  useEffect(() => {
    handleFilterTrueValues();
  }, [failToggleStates, billingToggleStates]);


  useEffect(()=>{
    fetchUserSubscription()
  },[])
  
  useEffect(()=>{
    setFailToggleStates(prevState => ({
      ...prevState,
      fail_notification_push: isSubscribe
    }));

   
  },[isSubscribe])

  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 3000);
    }
    if (activeSuccess) {
      setTimeout(() => {
        setActiveSuccess(false);
      }, 2000);
    }
  }, [activeFail, activeSuccess]);

  return (
    <div class=" border rounded-xl bg-white">
         {/* <PushNotification
          show={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          

      
        /> */}
      {activeFail && (
        <FailToast
          errorValue={msg}
          activeFail={activeFail}
          setActiveFail={setActiveFail}
        />
      )}
      {activeSuccess && (
        <SuccessToast
          successValue={msg}
          activeSuccess={activeSuccess}
          setActiveSuccess={setActiveSuccess}
        />
      )}
      <div
        class="flex  flex-col space-y-3  py-2 px-3  "
        style={{ width: "450px", height: "512px", borderRadius: "10rem" }}
      >
        <div class=" mb-1 space-y-1 ">
          <h1 class="text-color-gray-light font-extrabold text-lg">
            Notification Management
          </h1>
          <p class="text-color-gray-light font-normal	text-sm	">
            Manage your notification detail from this section{" "}
          </p>
        </div>

        <div className="bg-[#F1F1F1] p-3  rounded-lg space-y-3">
          <h1 class="text-color-gray-light font-extrabold text-lg">
            Test Fail Notification
          </h1>
          <ul className="gap-0 text-sm leading-6 text-gray-600 sm:grid-cols-3 sm:gap-6  space-y-2">
            {[
              { label: "Email", id: "fail_notification_email" },
              { label: "Push Notification", id: "fail_notification_push" },
            ].map((item) => (
              <li
                key={item.id}
                className="flex items-center justify-between w-full space-x-32  bg-[#E5E5E5] p-2 rounded-lg"
              >
                <span className="text-lg font-semibold text-black">
                  {item.label}
                </span>
                <input
                  type="checkbox"
                  className="peer sr-only opacity-0"
                  id={item.id}
                  value={item.label}
                  checked={failToggleStates[item.id]}
                  onChange={(e) => handleChangeNotification(e, item.id)}
                />
                <label
                  htmlFor={item.id}
                  className="relative flex h-6 w-11 cursor-pointer items-center rounded-full bg-gray-400 px-0.5 outline-gray-400 transition-colors before:h-5 before:w-5 before:rounded-full before:bg-white before:shadow before:transition-transform before:duration-300 peer-checked:bg-[#1F8B24] peer-checked:before:translate-x-full peer-focus-visible:outline peer-focus-visible:outline-offset-2 peer-focus-visible:outline-gray-400 peer-checked:peer-focus-visible:outline-green-500"
                >
                  <span className="sr-only">Enable</span>
                </label>
              </li>
            ))}
          </ul>
          
          <div class="flex pb-3">
            <button
              class="py-2 px-2 rounded-lg text-xs font-normal bg-button-bg hover:bg-button-hover text-white"
              onClick={handleNotification}
            >
              Save
            </button>
          </div>
        </div>

        <div className="bg-[#F1F1F1] p-3 rounded-lg space-y-3">
          <h1 class="text-color-gray-light font-extrabold text-lg">
            Billing Notification
          </h1>
          <ul className="mt-8 space-y-2 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
            {[
              { label: "Email", id: "billing_email" },
              // { label: "SMS", id: "billing_sms" },
            ].map((item) => (
              <li
                key={item.id}
                className="flex items-center justify-between w-full space-x-32 bg-[#E5E5E5] p-2 rounded-lg"
              >
                <span className="text-lg font-semibold text-black">
                  {item.label}
                </span>
                <input
                  type="checkbox"
                  className="peer sr-only opacity-0"
                  id={item.id}
                  value={item.label}
                  checked={billingToggleStates[item.id]}
                  onChange={(e) => handleChangeBilling(e, item.id)}
                />
                <label
                  htmlFor={item.id}
                  className="relative flex h-6 w-11 cursor-pointer items-center rounded-full bg-gray-400 px-0.5 outline-gray-400 transition-colors before:h-5 before:w-5 before:rounded-full before:bg-white before:shadow before:transition-transform before:duration-300 peer-checked:bg-[#1F8B24] peer-checked:before:translate-x-full peer-focus-visible:outline peer-focus-visible:outline-offset-2 peer-focus-visible:outline-gray-400 peer-checked:peer-focus-visible:outline-green-500"
                >
                  <span className="sr-only">Enable</span>
                </label>
              </li>
            ))}
          </ul>
          <div class="flex pb-3">
            <button
              class="py-2 px-2 rounded-lg text-xs font-normal bg-button-bg hover:bg-button-hover text-white"
              onClick={handleBilling}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
