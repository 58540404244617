import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";
import Guide1 from "../components/Guide/Guide1";
import Guide2 from "../components/Guide/Guide2";
import Guide3 from "../components/Guide/Guide3";
import Popup_Card from "../components/Card/Popup_Card";
import Footprint_Guide_Card from "../components/Card/Footprint_Guide_Card";
import Footprint_Guide from "../components/Guide/Footprint_Guide";
import Footprint_Guide_Finish from "../components/Guide/Footprint_Guide_Finish";
import Footprint_Guide_Mid from "../components/Guide/Footprint_Guide_Mid";
import Popup_End_Card from "../components/Card/Popup_End_Card";
import Footprint_Home from "../components/Footprint/Footprint_Home";
import axios from "axios";
import FootprintInfoPage from "./FootprintInfoPage";
import FootprintHomePage from "./FootprintHomePage";
import { baseUrl } from "../config";
import Extension from "../components/Modal/Extension";
import ExtensionConfirm from "../components/Modal/ExtensionConfirm";

import { useLocation , useParams } from "react-router-dom";
import ExtensionAlert from "../components/Card/ExtensionAlert";
// import usePushNotifications from '../hooks/usePushNotifications';

const Dashboard = () => {

  const [hasCreatedFootprint, setHasCreatedFootprint] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const [showExtensionCard, setShowExtensionCard] = useState(false);
  const [showExtensionConfirm, setShowExtensionConfirm] = useState(false);
  const [website, setWebsite] = useState();
  const [userWebsite, setUserWebsite] = useState([])
  const [defaultWebsiteId, setDefaultWebsiteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const { id } = useParams();
  // const { initializePushNotifications, isSubscribed } = usePushNotifications();

  let finalWebsiteId = id || defaultWebsiteId
  console.log("finalWebsiteId",finalWebsiteId)

  const fetchUserWebsites = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/websites/user/${userId}`);
      console.log("response from fetchwebsite", response.data.websites)
      setUserWebsite(response?.data?.websites);
      setDefaultWebsiteId(response.data.websites.at(-1)._id);
      setLoading(false);
    } catch (error) {
      console.log('Error fetching websites:', error.response);
      setLoading(false)
    }
  };

  const checkUserFootprint = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseUrl}/footprint/foot/user/${userId}`
      );

      console.log("user has created any footprint", response.data);
      // setUser(response.data);
      // setHasCreatedFootprint(response.data);
      console.log("Response from bbbb", response.data.website);
      setHasCreatedFootprint(response.data.hasFootprint);
      setWebsite(response.data.website[0]);
      setLoading(false);
    } catch (error) {
      console.log("Error in fetching data", error.response);
      setLoading(false);
    }
  };

  // handle confirm extension installation


  // useEffect(() => {
  //   if (!websiteId ) {

  //   }
  // }, [websiteId])


  useEffect(() => {
    if (userId) {
      // checkUserFootprint();
      fetchUserWebsites()
      // initializePushNotifications();
      // console.log(isSubscribed,"jai ho")

       // Initialize push notifications only if not already subscribed


    // console.log(isSubscribed, "Push notifications initialized");
    }
  }, [userId,id]);




  console.log("checkking websiteId from dashbaord",finalWebsiteId)
  


  useEffect(() => {
    const handleLoad = () => {
      console.log("Page fully loaded");

      const hasShownConfirmation = localStorage.getItem("hasShownConfirmation");
      const messageListener = (event) => {
        if (event.source !== window) return;
        if (event.data.type === "EXTENSION_INSTALLED") {
          console.log("Extension is installed:", event.data.data);
          setIsExtensionInstalled(true);
          if (!hasShownConfirmation) {
            setShowExtensionConfirm(true);
            localStorage.setItem("hasShownConfirmation", "true");
          }
        }
      };

      // Add event listener for messages
      window.addEventListener("message", messageListener);

      // Send a message to check if the extension is installed
      console.log("Sending CHECK_EXTENSION message");
      window.postMessage({ type: "CHECK_EXTENSION" }, "*");

      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener("message", messageListener);
      };
    };

    // Check if document is already loaded
    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);

      // Clean up the load event listener
      return () => {
        window.removeEventListener('load', handleLoad);
      };
    }
  }, []);

  return (
    <>
     
     {/* <Extension
        show={!isExtensionInstalled}
        setIsExtensionInstalled={setIsExtensionInstalled}
      />
      <ExtensionConfirm
        show={showExtensionConfirm}
        onClose={() => setShowExtensionConfirm(false)}
      /> */}

      <div className="guides">
        {/* <button onClick={initializePushNotifications}>Check</button> */}
        <div className="inner-guides">
          {!isExtensionInstalled && <ExtensionAlert/>}

          {loading ? (
            <div className="flex justify-center h-screen items-center	">
              <div class="border-gray-300 h-10 justify-center items-center   w-10 animate-spin rounded-full border-4 border-t-grsy-400" />
            </div>
          ) : userWebsite?.length > 0 ? (
            <div
                className={`space-y-4 mx-4 py-2 flex flex-col gap-2 ${!isExtensionInstalled ? 'non-clickable' : ''
                  }`}
                style={{
                  pointerEvents: isExtensionInstalled ? 'auto' : 'none',
                  opacity: isExtensionInstalled ? 1 : 0.5, // Optional: visually indicate non-clickability
                }}
              >
       
            
            <FootprintHomePage website_Id={finalWebsiteId} isExtensionInstalled={isExtensionInstalled} />
            </div>
          ) : (
            <div className="container flex justify-center  m-auto h-[50vh] w-[60vw]  ">
              <div
                className={`space-y-4 mx-4 py-2 flex flex-col gap-2 ${!isExtensionInstalled ? 'non-clickable' : ''
                  }`}
                style={{
                  pointerEvents: isExtensionInstalled ? 'auto' : 'none',
                  opacity: isExtensionInstalled ? 1 : 0.5, // Optional: visually indicate non-clickability
                }}
              >
              <Guide1 />
              </div>
            </div>
          )}




          {/* {websiteId ? (
            <FootprintHomePage website_Id={websiteId} />
          ) : (
            <div className="container flex justify-center  m-auto h-[50vh] w-[60vw]  ">
              <Guide1 />
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
