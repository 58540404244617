import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { baseUrl } from "../config";
import { VideoLogs } from "./VideoLogs";
import VideoDesign from "./VideoDesign";
const FailedVideo = () => {
  const [video, setVideo] = useState();
  const { videoId } = useParams();
  const { footprintId } = useParams();
  const { websiteId } = useParams();
  // const { videoUrl, logUrl, run, websiteId, footprintId, videoId }
  // console.log("footprintId", footprintId, "websiteId", websiteId);
  console.log("VideoId", videoId);
  const fetchVideo = async () => {
    try {
      const response = await axios.get(`${baseUrl}/videos/${videoId}`);

      console.log("Get request successful for video!", response.data);
      setVideo(response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  useEffect(() => {
    if (videoId) {
      fetchVideo();
    }
  }, [videoId]);

  return (
    <>
      <VideoLogs
        videoUrl={video?.url}
        logUrl={video?.logUrl}
        websiteId={websiteId}
        footprintId={footprintId}
        videoId={videoId}
      />
     


    </>

  );
};

export default FailedVideo;
